/**
 * @param {string} environment
 * @returns {boolean}
 */
export function is(environment) {
  return process.env.NODE_ENV === environment;
}

/**
 * @returns {boolean}
 */
export function isProduction() {
  return is('production');
}

/**
 * @returns {boolean}
 */
export function isDevelopment() {
  return is('development');
}