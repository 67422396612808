export default (() => {
  let _service;

  const NAME_ACCESS_TOKEN  = 'auth.token.access';
  const NAME_REFRESH_TOKEN = 'auth.token.refresh';

  /**
   * @returns {_getService|*}
   * @private
   */
  function _getService() {
    if (!_service) {
      _service = this;
      return _service
    }
    return _service
  }

  /**
   *
   * @param {String} token
   * @private
   */
  function _setAccessToken(token) {
    localStorage.setItem(NAME_ACCESS_TOKEN, token);
  }

  /**
   *
   * @param {String} token
   * @private
   */
  function _setRefreshToken(token) {
    localStorage.setItem(NAME_REFRESH_TOKEN, token);
  }


  /**
   * @param {Object} data
   * @private
   */
  function _setToken(data) {
    if (Object.prototype.hasOwnProperty.call(data, 'access')) {
      _setAccessToken(data.access);
    }
    if (Object.prototype.hasOwnProperty.call(data, 'refresh')) {
      _setRefreshToken(data.refresh);
    }
  }

  /**
   * @returns {string}
   * @private
   */
  function _getAccessToken() {
    return localStorage.getItem(NAME_ACCESS_TOKEN);
  }

  /**
   * @returns {string}
   * @private
   */
  function _getRefreshToken() {
    return localStorage.getItem(NAME_REFRESH_TOKEN);
  }

  /**
   * @private
   */
  function _clearToken() {
    localStorage.removeItem(NAME_ACCESS_TOKEN);
    localStorage.removeItem(NAME_REFRESH_TOKEN);
  }

  return {
    getService:      _getService,
    setToken:        _setToken,
    setAccessToken:  _setAccessToken,
    setRefreshToken: _setRefreshToken,
    getAccessToken:  _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken:      _clearToken
  }
})();
