const moment = require('moment')
require('moment/locale/sk');

import Vue from 'vue';
import VueRouter from 'vue-router'

import VueMoment from 'vue-moment'
import VueGtag from 'vue-gtag'
import VueHelpers from './helpers'
import {isProduction} from './helpers/environment'
import {SpaReloading} from '@/setup/spa-reloading'

require('@/setup/http')

// Plugins
window.Vue = Vue;
Vue.use(VueRouter);
Vue.use(VueMoment, {moment});

if (isProduction() && process.env.VUE_APP_GTAG) {
  Vue.use(VueGtag, {config: {id: process.env.VUE_APP_GTAG}});
}

// Plugins
Vue.use(
  {
    install() {
      // Helpers
      Vue.helpers            = VueHelpers
      Vue.prototype.$helpers = VueHelpers
    }
  }
)

// Moment
Vue.prototype.moment = moment;

// SpaReloading
Vue.prototype.spaReloading = new SpaReloading;
Vue.prototype.spaReloading.resolve(() => {
  window.location.reload();
});
