require('@/bootstrap');
import debounce from 'lodash/debounce';

import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

Vue.config.productionTip = false;

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
  mounted:    debounce(function () {
    this.$nextTick(() => {
      const hide = () => {
        setTimeout(() => {
          // Root page loader off
          document.getElementById('page-loader-root').classList.add('page-loader-off');

          // Vue page loader hide
          this.$store.dispatch('loader/hide');
        }, 440);
      };

      if (this.$store.getters['loader/show']) {
        hide();
      } else {
        this.$store.dispatch('loader/show').then(hide);
      }
      Vue.prototype.spaReloading.run();
    })
  }, 1000),
});
