export default {
  namespaced: true,
  state:      {
    show: true
  },
  mutations: {
    change(state, status) {
      state.show = status
    }
  },
  getters:    {
    show: state => {
      return state.show;
    }
  },
  actions:  {
    show({commit}) {
      commit('change', true)
    },

    hide({commit}) {
      commit('change', false)
    },
  },
}