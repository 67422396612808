import Vue from "vue";

export default {
  namespaced: true,
  state:      [],
  mutations:  {
    push(state, data) {
      state.push({
        id:   data.id,
        name: data.name
      })
    }
  },
  getters:    {
    get: (state) => (id) => {
      return state.find(item => item.id === id);
    }
  },
  actions:    {
    store({commit}) {
      return Vue
        .prototype.$http.get('/api/service/device/type')
        .then(response => {
          response.data.data.forEach(item => {
            commit('push', item);
          })
          return response;
        });
    }
  },
}