import CompareVersion from 'compare-versions';

export class SpaReloading {
    constructor() {
        this.data = null;
        this.number = null;
        this.path = '/build.json';
        this.interval = null;
        this.callback = null;
    }

    isChanged() {
        if (this.number === null || this.data === null) {
            return false;
        }

        if (!CompareVersion.validate(this.data.number)) {
            return false;
        }

        return CompareVersion.compare(
            this.data.number,
            this.number,
            '>'
        );
    }

    fetch() {
        return fetch(
            this.path,
            {
                headers: {
                    'Cache-Control': 'no-cache'
                }
            }
        )
            .then(response => response.json())
            .then(data => this.data = data)
            .then(() => {
                if (typeof this.callback === 'function') {
                    if (this.isChanged()) {
                        this.callback();
                        this.number = this.data.number;
                    }
                }
                if (this.number === null) {
                    this.number = this.data.number;
                }
            });
    }

    resolve(cb) {
        this.callback = cb;
    }

    run() {
        this.destroy();
        this.fetch().then(this.interval = setInterval(this.fetch.bind(this), 2 * 60 * 1000))
    }

    destroy() {
        clearInterval(this.interval);
    }

    call() {
        this.run();
    }
}
